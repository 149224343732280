import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Transaction } from '../../services/openapi'
import { ApiError } from '../../services/openapi';


//const format = (input: number): string => {
//    input = input / 100;
//    const formatter = new Intl.NumberFormat('nl-NL', {
//        style: 'currency',
//        currency: 'EUR'
//    });

//    return input.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
//}

type TxItemProps = {
    deleteTx?: (id: number) => void;
    tx: Transaction;
    updateTx?: (id: number, tx: Transaction) => void;
    rowColor: string;

};

const TxItem = ({ tx, updateTx, deleteTx, rowColor }: TxItemProps) => {    
    return (
        <>
            <Row className="qvend-table-font" style={{ background: (tx.terminalStatusCode == "0000" || !tx.terminalStatusCode ? tx.paymentCorrectionNeeded ? "red" : rowColor : sessionStorage.getItem('ErrorColor') ?? "red"), color: "black" }} {...(tx.terminalStatusCode != "0000" && { title: tx.terminalStatusCode ?? "" })} >
                <Col xl={2} lg={2} md={3} sm={5} xs={5} className="qvend-table table-column-first">{tx.modified}</Col>            
                <Col xl={3} lg={3} md={2} sm={0} xs={0} className="qvend-table description-column d-none d-md-block">{tx.vmcName ?? tx.terminalDescription} </Col>
                <Col xl={3} lg={3} md={2} sm={4} xs={4} className="qvend-table description-column">{tx.deliveryStatusDescription} </Col>
                <Col xl={3} lg={3} md={3} sm={0} xs={0} className="qvend-table d-none d-md-block">{tx.productId} </Col>
                <Col xl={1} lg={1} md={2} sm={3} xs={3} className="qvend-table amount-column">{tx.chargedAmount ?? tx.reservedAmount ?? ""} </Col>
                {/*<td><button*/}
                {/*    onClick={() => deleteTx((!(tx.id === undefined) ? tx.id : 0))}*/}
                {/*>*/}
                {/*    Delete*/}
                {/*</button></td>*/}
            </Row>
        </>
    );
};

export default TxItem;
