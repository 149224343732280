import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateSite } from '../../../../services/api/site';
import { Site, ApiError } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';
import Dropzone from '../../../../components/Dropzone';
import JsonDownload from '../../../../components/exports/JsonDownload';
import { getScreenConfig } from '../../../../services/api/screenConfig';

const SiteDetails = () => {
    const [site, setSite] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [screenConfig, setScreenConfig] = useState<string>('');
    const [isDragEnter, setIsDragEnter] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setSiteData = (data: Site) => {
        localStorage.setItem('Site', JSON.stringify(data));
    }

    useEffect(() => {
        if (site == null) {
            const json = localStorage.getItem('Site') ?? "";
            const item = JSON.parse(json);

            setSite(item);
        }
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        document.querySelector('.errorMsg')?.classList.add("d-none");
        errorMsg.length = 0;

        if (e.currentTarget.checkValidity()) {
            let s: Site = site;
            s.screenConfig = screenConfig;
            setSiteData(s);
            updateSite(token, s.id ?? 0, s).catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }
            });

            setShowInputEle(!showInputEle);
        }
    }

    const handleFileContents = (contents: string) => {        
        setScreenConfig(contents);        
    };

    const handleJsonDownload = (): Promise<string> => {
        
        return getScreenConfig(keycloak.token || '', site.siteId).catch((error: ApiError) => {
            try {
                document.querySelector('.errorMsg')?.classList.remove("d-none");
                Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                throw error;//return new Promise<string>(() => '');
            }
            catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                throw ex;
            }
        });        
    }

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Sitedetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {site && Object.entries<PropertyInfo>(site).filter(([key, value]) => site["propertyNames"][key] !== undefined && (site["propertyNames"][key].editType !== '' || site["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => site["propertyNames"][a[0]].sortOrderLevel - site["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    <DetailItem key={index} propertyName={site["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={site["propertyNames"][key].editType} pattern={site["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => setShowInputEle(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { site[key] = (e.target.value); setSite(site); }} showInputEle={showInputEle} possibilities={site["propertyNames"][key].predefinedValues }></DetailItem>
                                                ))}
                                                <Row className="qvend-table-font" style={{ background: getBackgroundColor(false), color: "black" }}>
                                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="qvend-table table-column-first q-vend-detail-table-col">Screen config</Col>
                                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="qvend-table q-vend-detail-table-col" style={{ backgroundColor: getBackgroundColor(true) }}>
                                                        {showInputEle ? (<Dropzone handleFilename={() => { }} handleFileContents={handleFileContents} onDragEnter={(e: DragEvent) => { setIsDragEnter(true); }} onDragLeave={(e: DragEvent) => { setIsDragEnter(false); }} />) : site && (<JsonDownload filename={`site-${site.siteId}-screens.json`} onGetData={handleJsonDownload} />)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                    <span>{item}</span>
                                                </Alert>;
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>                                        
                                        
                                    </Col>
                                    <Col className="text-end">                                        
                                    </Col>
                                </Row>                                
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <Link to="/dashboard/lists/sites" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Terug</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        {showInputEle ?
                                            (<button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>Opslaan</button>) :
                                            (<button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={(e) => { e.preventDefault(); setShowInputEle(true); }}>Wijzigen</button>)
                                        }                                        
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )

    function getBackgroundColor(isDragBox : boolean) {        

        if (isDragBox && isDragEnter) {
            return 'red';
        }

        const color = (site && Object.keys(site.propertyNames).length % 2) ? "#cfd5ea" : "#e9ebf5";
        return color;
    }
}

export default SiteDetails;