import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateProduct } from '../../../../services/api/product';
import { ApiError, Product } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';

const ProductDetails = () => {
    const [product, setProduct] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setProductData = (data: Product) => {
        localStorage.setItem('Product', JSON.stringify(data));
    }

    useEffect(() => {
        if (product == null) {
            const json = localStorage.getItem('Product') ?? "";
            const item = JSON.parse(json);

            setProduct(item);
        }
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        document.querySelector('.errorMsg')?.classList.add("d-none");
        errorMsg.length = 0;

        if (e.currentTarget.checkValidity()) {
            let p: Product = product;
            setProductData(p);
            updateProduct(token, p.id ?? 0, p).catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }
            });

            setShowInputEle(!showInputEle);
        }
    }

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Productdetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {product && Object.entries<PropertyInfo>(product).filter(([key, value]) => product["propertyNames"][key] !== undefined && (product["propertyNames"][key].editType !== '' || product["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => product["propertyNames"][a[0]].sortOrderLevel - product["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    <DetailItem key={index} propertyName={product["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={product["propertyNames"][key].editType} pattern={product["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => setShowInputEle(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { product[key] = (e.target.value); setProduct(product); }} showInputEle={showInputEle} possibilities={product["propertyNames"][key].predefinedValues}></DetailItem>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                    <span>{item}</span>
                                                </Alert>;
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>                                        
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => setShowModal(true)}>Foto</button>
                                    </Col>
                                    <Col className="text-end">                                        
                                    </Col>
                                </Row>                                
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <Link to="/dashboard/lists/products" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Terug</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        <button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>{showInputEle ? "Opslaan" : "Wijzigen"}</button>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ProductDetails;