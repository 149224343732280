import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateVmc } from '../../../../services/api/vmc';
import { getTerminalMdbSettingById } from '../../../../services/api/terminalMdbSettings'
import { VmcStatusDto, ApiError } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';
import { addLinkedItem, addLinkedItems, deleteAllLinkedItems } from '../../../../services/api/linkedItem';

import { ApiResult } from '../../../../services/openapi/core/ApiResult';
import { getVmcs } from '../../../../services/api/vmc';

const VmcDetails = () => {
    const [vmc, setVmc] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setVmcData = (data: VmcStatusDto) => {
        localStorage.setItem('Vmc', JSON.stringify(data));
    }

    useEffect(() => {
        if (vmc == null) {
            const json = localStorage.getItem('Vmc') ?? "";
            const item = JSON.parse(json);

            item["isConnected"] = item["isConnected"] ? "Online" : "Offline"

            setVmc(item);
        }
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        document.querySelector('.errorMsg')?.classList.add("d-none");
        errorMsg.length = 0;        

        if (e.currentTarget.checkValidity()) {
            let v: VmcStatusDto = vmc;
            setVmcData(v);
            updateVmc(token, v.id ?? 0, v).catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }
            });
            setShowInputEle(!showInputEle);
        }
    }

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Machinedetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {vmc && Object.entries<PropertyInfo>(vmc).filter(([key, value]) => vmc["propertyNames"][key] !== undefined && (vmc["propertyNames"][key].editType !== '' || vmc["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => vmc["propertyNames"][a[0]].sortOrderLevel - vmc["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    (vmc["propertyNames"][key].roleName === "" || (vmc["propertyNames"][key].roleName === "admin" && keycloak && keycloak.hasResourceRole("r-admin"))) && (
                                                    <DetailItem key={index} propertyName={vmc["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={vmc["propertyNames"][key].editType} pattern={vmc["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => setShowInputEle(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { vmc[key] = (e.target.value); setVmc(vmc); }} showInputEle={showInputEle}></DetailItem>)
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                    <span>{item}</span>
                                                </Alert>;
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>
                                        {vmc != undefined &&  (vmc as VmcStatusDto).type ?
                                            (<button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                                navigate('/dashboard/details/vmc/vmc-linked-products');
                                            }}>Producten</button>) : undefined}
                                    </Col>
                                    <Col className="text-end">
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                            document.querySelector('.errorMsg')?.classList.add("d-none");
                                            errorMsg.length = 0;

                                            deleteAllLinkedItems(token, vmc.id ?? 0, 'Vmc', "VmcType").then(() => {
                                                if (vmc.type) {
                                                    addLinkedItem(token, { parentId: vmc.id ?? 0, parentType: 'Vmc', linkedEntityId: vmc.vmcTypeId, linkedEntityType: "VmcType" }).then(() => {
                                                        navigate('/dashboard/items-linkers/vmcType-to-vmc');
                                                    }).catch((error: ApiError) => {
                                                        try {
                                                            document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                            Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                        } catch (ex) {
                                                            errorMsg.push("Error handling response");
                                                            setErrorMsg(errorMsg);
                                                        }
                                                    });
                                                }
                                                else {
                                                    navigate('/dashboard/items-linkers/vmcType-to-vmc');
                                                }
                                            }).catch((error: ApiError) => {
                                                try {
                                                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                } catch (ex) {
                                                    errorMsg.push("Error handling response");
                                                    setErrorMsg(errorMsg);
                                                }
                                            });
                                        }}>Type</button>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                            document.querySelector('.errorMsg')?.classList.add("d-none");
                                            errorMsg.length = 0;

                                            deleteAllLinkedItems(token, vmc.id ?? 0, 'Vmc', "Location").then(() => {
                                                if (vmc.locationId) {
                                                    addLinkedItem(token, { parentId: vmc.id ?? 0, parentType: 'Vmc', linkedEntityId: vmc.locationId, linkedEntityType: "Location" }).then(() => {
                                                        navigate('/dashboard/items-linkers/location-to-vmc');
                                                    });
                                                }
                                                else {
                                                    navigate('/dashboard/items-linkers/location-to-vmc');
                                                }
                                            }).catch((error: ApiError) => {
                                                try {
                                                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                } catch (ex) {
                                                    errorMsg.push("Error handling response");
                                                    setErrorMsg(errorMsg);
                                                }
                                            });
                                        }}>Locatie</button>
                                    </Col>
                                    <Col className="text-end">                                        
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <Link to="/dashboard/lists/vmcs" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Terug</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        <button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>{showInputEle ? "Opslaan" : "Wijzigen"}</button>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default VmcDetails;