import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateTerminal, requestCtmp, configure, getTerminalById } from '../../../../services/api/terminal';
import { getTerminalMdbSettingById } from '../../../../services/api/terminalMdbSettings'
import { ApiError, Terminal, TerminalMdbSetting, VmcStatusDto } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';
import { addLinkedItem, addLinkedItems, deleteAllLinkedItems } from '../../../../services/api/linkedItem';
import TerminalMdbSettingDto from './TerminalMdbSettingDto';
import { ApiResult } from '../../../../services/openapi/core/ApiResult';
import { getVmcById } from '../../../../services/api/vmc';

const TerminalDetails = () => {
    const [terminal, setTerminal] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setTerminalMdbData = (data: TerminalMdbSettingDto) => {
        localStorage.setItem('TerminalMdbSetting', JSON.stringify(data));
    }

    const setTerminalData = (data: Terminal) => {
        localStorage.setItem('Terminal', JSON.stringify(data));
    }

    const toggleInputElements = (show: boolean) => {
        setShowInputEle(show);
        if (show) {
            document.querySelector('.errorMsg')?.classList.add("d-none");
            errorMsg.length = 0;
        }
    }

    const canConfigureTerminal = (terminal: Terminal) => {
        const errorBox = document.querySelector('.errorMsg')!.classList;
        errorBox.add("d-none");        
        errorMsg.length = 0;

        if (terminal.vmcIds == null || terminal.vmcIds.length == 0) {
            document.querySelector('.errorMsg')?.classList.remove("d-none");
            errorMsg.push('Geen automaat gekoppeld');
            setErrorMsg(errorMsg);
            errorBox.remove("d-none")
            return false;
        }        

        getVmcById(token, terminal.vmcIds.at(0) ?? 0).then(vmc => {
            if (vmc.type == null || vmc.type.length == 0) {
                document.querySelector('.errorMsg')?.classList.remove("d-none");
                errorMsg.push('Automaattype niet ingesteld bij gekoppelde automaat');
                setErrorMsg(errorMsg);
                errorBox.remove("d-none")
                return false;
            }
        }).catch((error: ApiError) => {
            document.querySelector('.errorMsg')?.classList.remove("d-none");
            errorMsg.push('Automaattype kon niet bepaald worden');
            setErrorMsg(errorMsg);
            errorBox.remove("d-none")
            return false;
        });

        return true;
    }

    const configureTerminal = (terminal : Terminal) => {
        configure(keycloak.token || '', (!(terminal.id === undefined) ? terminal.id : 0), "0000").catch((error: ApiError) => {
            try {
                document.querySelector('.errorMsg')?.classList.remove("d-none");
                /* k0 contains field name: e.g. LocationSelection, k1 contains array of error messages*/
                Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => {
                    k[1].forEach((msg: string) => {
                        errorMsg.push(msg);
                        setErrorMsg(errorMsg);
                    })
                });
            } catch (ex) {
                errorMsg.push("Error handling response");
                setErrorMsg(errorMsg);
            }
        });
    }

    useEffect(() => {        
        if (terminal == null) {
            const json = localStorage.getItem('Terminal') ?? "";
            const item = JSON.parse(json);

            item["isConnected"] = item["isConnected"] ? "Online" : "Offline";

            setTerminal(item);
        }              
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            let t: Terminal = terminal;
            setTerminalData(t);
            updateTerminal(token, t.id ?? 0, t).catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }
            });
            toggleInputElements(!showInputEle);
        }
    }

    function isConfigureBtnDisabled(): boolean | undefined {
        if (terminal == null || terminal == undefined) {
            return;
        }

        const notConnected = terminal.isConnected !== "Online";
        const ecrStateInConfigure = terminal.ecrState.startsWith('Vas');
        const vasStateIsNotReadyForConfig = terminal.vasState != 'operational' && terminal.vasState != 'not_configured'

        return notConnected || ecrStateInConfigure /*|| vasStateIsNotReadyForConfig*/;
    }

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Terminaldetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {terminal && Object.entries<PropertyInfo>(terminal).filter(([key, value]) => terminal["propertyNames"][key] !== undefined && (terminal["propertyNames"][key].editType !== '' || terminal["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => terminal["propertyNames"][a[0]].sortOrderLevel - terminal["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    (terminal["propertyNames"][key].roleName === "" || (terminal["propertyNames"][key].roleName === "admin" && keycloak && keycloak.hasResourceRole("r-admin"))) && (
                                                    <DetailItem key={index} propertyName={terminal["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={terminal["propertyNames"][key].editType} pattern={terminal["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => toggleInputElements(true)}
                                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => { terminal[key] = (e.target.value); if (key == 'siteId') { terminal.vmcIds = null} setTerminal(terminal); }} showInputEle={showInputEle}></DetailItem>)
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                           <span>{item}</span>
                                                       </Alert>;
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>
                                        <Link to="/dashboard/details/terminal-mdb-settings" onClick={() => {
                                            getTerminalMdbSettingById(token, terminal?.id).then((setting) => { if (setting) { setTerminalMdbData(setting) } }).catch((error: ApiError) => {
                                                try {
                                                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                } catch (ex) {
                                                    errorMsg.push("Error handling response");
                                                    setErrorMsg(errorMsg);
                                                }
                                            }); }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>MDB</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => requestCtmp(keycloak.token || '', (!(terminal.id === undefined) ? terminal.id : 0))}>CTMP</button>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>                                        
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                            document.querySelector('.errorMsg')?.classList.add("d-none");
                                            errorMsg.length = 0;

                                            deleteAllLinkedItems(token, terminal.id ?? 0, 'Terminal', "Location").then(() => {
                                                if (terminal.locationId) {
                                                    addLinkedItem(token, { parentId: terminal.id ?? 0, parentType: 'Terminal', linkedEntityId: terminal.locationId, linkedEntityType: "Location" }).then(() => {
                                                        navigate('/dashboard/items-linkers/location-to-terminal');
                                                    }).catch((error: ApiError) => {
                                                        try {
                                                            document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                            Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                        } catch (ex) {
                                                            errorMsg.push("Error handling response");
                                                            setErrorMsg(errorMsg);
                                                        }
                                                    });
                                                }
                                                else {
                                                    navigate('/dashboard/items-linkers/location-to-terminal');
                                                }
                                            }).catch((error: ApiError) => {
                                                try {
                                                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                } catch (ex) {
                                                    errorMsg.push("Error handling response");
                                                    setErrorMsg(errorMsg);
                                                }
                                            });                                                
                                        }}>Locatie</button>
                                    </Col>
                                    <Col className="text-end">
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                            document.querySelector('.errorMsg')?.classList.add("d-none");
                                            errorMsg.length = 0;

                                            deleteAllLinkedItems(token, terminal.id ?? 0, 'Terminal', "Vmc").then(() => {
                                                if (terminal.vmcIds) {                                                    
                                                    addLinkedItems(token, terminal.id ?? 0, 'Terminal', "Vmc", terminal.vmcIds).then(() => {
                                                        navigate('/dashboard/items-linkers/vmc-to-terminal');
                                                    }).catch((error: ApiError) => {
                                                        try {
                                                            document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                            Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                        } catch (ex) {
                                                            errorMsg.push("Error handling response");
                                                            setErrorMsg(errorMsg);
                                                        }
                                                    });
                                                }
                                                else {
                                                    navigate('/dashboard/items-linkers/vmc-to-terminal');
                                                }
                                            }).catch((error: ApiError) => {
                                                try {
                                                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                } catch (ex) {
                                                    errorMsg.push("Error handling response");
                                                    setErrorMsg(errorMsg);
                                                }
                                            });
                                        }}>Automaten</button>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                            document.querySelector('.errorMsg')?.classList.add("d-none");
                                            errorMsg.length = 0;

                                            getTerminalById(token, terminal.id ?? 0).then((t) => {
                                                if (t) {
                                                    let oldTerminal = terminal as Terminal;
                                                    const propertyNames = oldTerminal.propertyNames;
                                                    const vmcIds = oldTerminal.vmcIds;
                                                    t.propertyNames = propertyNames;
                                                    t.vmcIds = vmcIds;
                                                    t.siteId = oldTerminal.siteId;

                                                    let term = t as any;

                                                    term["isConnected"] = term["isConnected"] ? "Online" : "Offline";
                                                    
                                                    setTerminal(term);
                                                    setTerminalData(t);
                                                }
                                            }).catch((error: ApiError) => {
                                                try {
                                                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                                                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                                                } catch (ex) {
                                                    errorMsg.push("Error handling response");
                                                    setErrorMsg(errorMsg);
                                                }
                                            });
                                        }}>Verversen</button>
                                    </Col>
                                    <Col className="text-end">
                                        <button type="button" className="btn btn-fixed-width" disabled={isConfigureBtnDisabled()} style={{ fontSize: '11px' }} onClick={(e) => { e.currentTarget.disabled = true; canConfigureTerminal(terminal) ? configureTerminal(terminal) : null }}>Configureer</button>                                        
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <Link to="/dashboard/lists/terminals" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Terug</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        {showInputEle ?
                                            (<button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>Opslaan</button>) :
                                            (<button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={(e) => { e.preventDefault(); toggleInputElements(true); }}>Wijzigen</button>)
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default TerminalDetails;